import { Chart, Tooltip, Axis, Legend, Coord, Guide, Pie } from "viser-react";
const DataSet = require("@antv/data-set");

const DonutChart = ({ data }) => {
  const scale = [
    {
      dataKey: "percent",
      min: 0,
      formatter: ".0%",
    },
  ];

  const dv = new DataSet.View().source(data);
  dv.transform({
    type: "percent",
    field: "count",
    dimension: "item",
    as: "percent",
  });
  const datas = dv.rows;

  const color = ["#4FE676", "#FC1F19"];

  console.log("DAta values:", data);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <Chart
        forceFit
        height={200}
        data={datas}
        scale={scale}
        padding={[-24, 0, 0, 0]}
        animate
      >
        <Tooltip
          showTitle={false}
          g2Tooltip={{
            backgroundColor: "#000",
            color: "white",
            borderRadius: "20px",
            fontSize: "12px",
          }}
        />
        <Axis />
        <Coord type="theta" radius={0.75} innerRadius={0.58} />
        <Pie position="count" color={["item", color]} />
      </Chart>
      <div
        style={{
          position: "absolute",
          top: "25%",
          left: "33%",
          textAlign: "center",
          color: "white",
        }}
      >
        <h1>{data[0]?.count}</h1>
        <p>{data[0]?.item}</p>
      </div>
    </div>
  );
};

export default DonutChart;
