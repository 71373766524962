import {useEffect, useContext} from "react";
import {useStore} from "../../TEMPLATE/hooks/store";
import {getAllSensors, getAllTypes} from "../../TEMPLATE/services/feed";
import {
  feedEnvironment_Meteo,
  feedMeshlium,
  feedParking,
  feedWifi,
} from "../../TEMPLATE/services/metrics";
import {
  allTypesUrl,
  allSensorsUrl,
  enviURL,
  parkingUrl,
  meshURL,
  meteoURL,
  wifiUrl
} from "../hardCodeData/DATA";
import LanguageContext from "./../../TEMPLATE/hooks/language-context";

const DynamicData = () => {
  const dispatch = useStore(false)[1];

  const controller = new AbortController();
  const {signal} = controller;

  const languageCtx = useContext(LanguageContext);

  useEffect(() => {
    const feed_types_call = async () => {
      const data = await getAllTypes(
        `${allTypesUrl}&lang=${languageCtx.languageCode}`,
        signal
      );
      dispatch("INITIALIZE_TYPES", data);
    };
    const feed_sensors = async () => {
      const data = await getAllSensors(
        `${allSensorsUrl}&lang=${languageCtx.languageCode}`,
        signal
      );
      dispatch("INITIALIZE_SENSORS", data);
      getMetrics().catch((error) => console.warn(error));
    };

    feed_types_call().catch((error) => console.warn(error));
    feed_sensors().catch((error) => console.warn(error));
    return () => controller.abort();
  }, [languageCtx.languageCode]);

  const getMetrics = async () => {
    // dispatch(
    //   "ADD_METRICS",
    //   await feedEnvironment_Meteo(
    //     `${enviURL}&lang=${languageCtx.languageCode}`,
    //     signal
    //   ).catch((error) => console.warn(error))
    // )

    feedEnvironment_Meteo(`${enviURL}&lang=${languageCtx.languageCode}`, signal).then(data => dispatch("ADD_METRICS", data)).catch((error) => console.warn(error))


    // dispatch(
    //   "ADD_METRICS",
    //   await feedMeshlium(
    //     `${meshURL}&lang=${languageCtx.languageCode}`,
    //     signal
    //   ).catch((error) => console.warn(error))
    // );

    feedMeshlium(`${meshURL}&lang=${languageCtx.languageCode}`, signal).then(data => dispatch("ADD_METRICS", data)).catch((error) => console.warn(error))

    // dispatch(
    //   "ADD_METRICS",
    //   await feedEnvironment_Meteo(
    //     `${meteoURL}&lang=${languageCtx.languageCode}`,
    //     signal
    //   ).catch((error) => console.warn(error))
    // );

    feedEnvironment_Meteo(`${meteoURL}&lang=${languageCtx.languageCode}`, signal).then(data => dispatch("ADD_METRICS", data)).catch((error) => console.warn(error))
    
    feedParking(`${parkingUrl}&lang=${languageCtx.languageCode}`, signal)
      .then((data) => dispatch("ADD_SENSORS", data))
      .catch((error) => console.warn(error));

    feedWifi(`${wifiUrl}&lang=${languageCtx.languageCode}`, signal)
       .then((data) => dispatch("ADD_METRICS", data))
       .catch((error) => console.warn(error));

    // dispatch(
    //   "ADD_SENSORS",
    //   await feedPOIS(`${poiURL}&lang=${languageCtx.languageCode}`, signal).catch((error) => console.warn(error))
    // );

    // feedPOIS(`${poiURL}&lang=${languageCtx.languageCode}`, signal).then(data => dispatch("ADD_SENSORS", data)).catch((error) => console.warn(error))


    // dispatch(
    //   "ADD_SENSORS",
    //   await feedFleeto(`${fleetoUrl}&lang=${languageCtx.languageCode}`, signal).catch((error) => console.warn(error))
    // );

    // feedFleeto(`${fleetoUrl}&lang=${languageCtx.languageCode}`, signal).then(data => dispatch("ADD_SENSORS", data)).catch((error) => console.warn(error))


    // dispatch(
    //   "ADD_SENSORS",
    //   await feedBins(`${binsUrl}&lang=${languageCtx.languageCode}`, signal).catch((error) => console.warn(error))
    // );

    // feedBins(`${binsUrl}&lang=${languageCtx.languageCode}`, signal).then(data => dispatch( "ADD_SENSORS", data)).catch((error) => console.warn(error))

    // dispatch(
    //   "ADD_SENSORS",
    //   await feedHydro(`${hydroUrl}&lang=${languageCtx.languageCode}`, signal).catch((error) => console.warn(error))
    // );

    // feedHydro(`${hydroUrl}&lang=${languageCtx.languageCode}`, signal).then(data => dispatch("ADD_SENSORS", data)).catch((error) => console.warn(error))
  };

  return null;
};

export default DynamicData;
