import classes from "./MapLeftPanelContentEntity.module.css";

const MapLefttPanelContentEntity = ({
  style,
  className,
  icon,
  colorIcon,
  title,
  address,
  last_alert,
  icons,
}) => {
  return (
    <div
      className={[classes.entity_container, className].join(" ")}
      style={{ ...style }}
    >
      {/* <div className={classes.title_container}>
        {icon && (
          <i className={icon} style={{ fontSize: "20px" }} />
        )}
      </div> */}
      <i
        className={icon}
        style={{
          fontSize: "20px",
          float: "left",
          color: colorIcon ? colorIcon : "inherit",
        }}
      />
      <div className={classes.information}>
        <span className={classes.entity_title}>{title}</span>
        <p className={classes.entity_addres}>{address}</p>
        {last_alert && <p className={classes.entity_addres}>{last_alert}</p>}
        {icons.map(
          (icon, index) =>
            icon && <img key={index} src={icon} alt={"info icons"} />
        )}
      </div>
    </div>
  );
};
export default MapLefttPanelContentEntity;
