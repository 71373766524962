import classes from "./SubsystemList.module.css";
import { useEffect, useState } from "react";
import MapLefttPanelContentEntity from "../../Map/leftPanelContent/Entity/MapLefttPanelContentEntity";
import { useStore } from "../../../hooks/store";
import { useHistory, useLocation } from "react-router-dom";
import { showEnvironmentAddress } from "../../../../SMARTCITY/hardCodeData/DATA";

const SubSystemList = ({ showAnimation, filterTypeName, filtersVars }) => {
  const [selectSubSystem, setSelectSubSystem] = useState("");
  const [activeType, setActiveType] = useState(null);
  const [sensors, setSensors] = useState([]);
  const [icons, setIcons] = useState([]);
  const state = useStore()[0];

  const history = useHistory();
  const { search } = useLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    if (search !== "") {
      const currTypeURL = new URLSearchParams(search).get("typeId");
      const currSensorURL = new URLSearchParams(search).get("sensorId");

      const tempTypeIdActive = state.types.filter(
        (item) => item.id === currTypeURL
      );

      if (tempTypeIdActive.length > 0 && state.sensors.length > 0) {
        console.log(tempTypeIdActive, state.sensors);

        const data = state.sensors.filter(
          (item) => item.typeId === tempTypeIdActive[0].id
        );

        let activeSensor = "";
        let iconsEachSensor = {};
        for (let i = 0; i < data.length; i++) {
          if (currSensorURL && data[i].id.toString() === currSensorURL) {
            activeSensor = data[i];
          }
          iconsEachSensor = {
            ...iconsEachSensor,
            [data[i].id]: data[i].metrics.map((metric) => metric.icon_small),
          };
        }
        console.log(state.sensors);
        console.log(data);
        setActiveType(tempTypeIdActive[0]);
        setSensors(data);
        console.log(iconsEachSensor);
        setIcons(iconsEachSensor);
        setSelectSubSystem(activeSensor);
      }
    }
  }, [search, state.sensors, state.types]);

  const clickMarkHandler = (item) => {
    if (item !== selectSubSystem) {
      history.push(`${pathname}?typeId=${activeType.id}&sensorId=${item.id}`);
      showAnimation(true);
    } else {
      showAnimation(false);
    }
  };

  console.log(selectSubSystem);

  let content = null;
  if (activeType) {
    content = (
      <div className={[classes.subsystem_layout].join(" ")}>
        <div
          className={[
            classes.flexContainer,
            classes.subsystem_title_style,
          ].join(" ")}
          style={{ backgroundColor: activeType.color }}
        >
          <p className={classes.subsystem_title}>{activeType.name}</p>
        </div>
        <div className={classes.list_place}>
          <ul>
            {sensors
              .filter((item) => {
                if (filterTypeName) {
                  if (
                    item.title
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toUpperCase()
                      .includes(
                        filterTypeName
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .toUpperCase()
                      )
                  )
                    return item;
                } else {
                  return item;
                }
              })
              .filter((item) => {
                if (filtersVars.length === 0) return item;
                let inlcude = false;

                if (activeType.id === "9") {
                  console.log(item.metrics[4].value === "Πλήρεις");

                  for (let y = 0; y < filtersVars.length; y++) {
                    switch (filtersVars[y]) {
                      case "Άδειοι":
                        if (item.metrics[4].value <= "25%") return item;
                        break;
                      case "Κάτω από τη μέση":
                        if (
                          item.metrics[4].value > "25%" &&
                          item.metrics[4].value <= "40%"
                        )
                          return item;
                        break;
                      case "Περίπου στη μέση":
                        if (
                          item.metrics[4].value <= "60%" &&
                          item.metrics[4].value > "40%"
                        )
                          return item;
                        break;
                      case "Σχεδόν πλήρεις":
                        if (
                          item.metrics[4].value > "60%" &&
                          item.metrics[4].value <= "95%"
                        )
                          return item;
                        break;
                      case "Πλήρεις":
                        console.log("ΠΛΗΡΕΙΣ");
                        if (item.metrics[4].value > "95%") return item;
                        break;
                      default:
                        return {};
                    }
                  }
                } else {
                  for (let i = 0; i < item.metrics.length; i++) {
                    for (let y = 0; y < filtersVars.length; y++) {
                      if (item.metrics[i].content === filtersVars[y]) {
                        inlcude = true;
                        break;
                      }
                    }
                    if (inlcude) break;
                  }
                  if (inlcude) return item;
                }
              })
              .map((item) => (
                <li
                  key={item.id}
                  style={{ justifyContent: "flex-start" }}
                  className={
                    item.id === selectSubSystem.id
                      ? [classes.flexContainer, classes.active].join(" ")
                      : classes.flexContainer
                  }
                  onClick={() => {
                    clickMarkHandler(item);
                  }}
                >
                  <MapLefttPanelContentEntity
                    icon={activeType.icon}
                    colorIcon={activeType.id === "9" ? "blue" : "inherit"}
                    title={item.title}
                    address={
                      activeType.id === "1"
                        ? showEnvironmentAddress
                          ? item.address
                          : null
                        : item.address
                    }
                    icons={icons[item.id]}
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
  return content;
};

export default SubSystemList;
